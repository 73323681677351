/*

if($('.youtube-player').length>0){
  var $body = $('#page');
  // 2. This code loads the IFrame Player API code asynchronously.
  var tag = document.createElement('script');

  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// get .player nodes
var playerDivs = document.querySelectorAll(".youtube-player");

// nodelist to array to use forEach();
var playerDivsArr = [].slice.call(playerDivs);
console.log(playerDivsArr);

var players = new Array(playerDivsArr.length);

// when youtube stuff is ready
function onYouTubeIframeAPIReady() {

  // create yt players
  playerDivsArr.forEach(function(e, i) { // forEach ...
    players[i] = new YT.Player(e.id, {
      height: '390',
      width: '640',
      videoId: e.getAttribute("data-id"),
      events: {  }
    })
  })
}
  $body.find('.youtube-player').mouseenter(function() {
    console.log('here');
    var playersrc=$(this).attr('src');
    $(this).attr('src',playersrc+'&autoplay=1');
  }).mouseleave(function() {
    var playersrc=$(this).attr('src');
    playersrc = playersrc.replace("&autoplay=1", "");
        $(this).attr('src',playersrc);
  });
  $(".youtube-player").hover(function(){
    console.log('hover');
     $(this).play();
  });
  $(document).on('mouseover', '.youtube-player', function() {
    console.log('no');
    var mouseID = $(this).attr('id');
    var test = mouseID;
    $(this).playVideo();;
  });
  $(document).on('mouseout', '#player', function() {
    console.log('blah');
    player.pauseVideo();
  });
}*/
