(function($) {
  var lazySection = function() {
    //add_lazy_load_data_to_bb();
    var lazySections = document.querySelectorAll("[data-ajax]");
    var inAdvance = 300;
    lazySections.forEach(function(section) {
      if (section.offsetParent == null) {
        return;
      }
      if (section.classList.contains("loaded")) {
        return;
      }
      var rect = section.getBoundingClientRect(),
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (rect.top < window.innerHeight + window.pageYOffset + inAdvance) {
        section.classList.add("loaded");
        _smg_run_ajax(section);
      }
    });
  };

  $(document).on("ready", lazySection);
  window.addEventListener("scroll", lazySection);

  var _smg_run_ajax = function(section, event) {
    // Get data from PHP Function
    var $item = section;
    $.ajax({
      type: "GET",
      url: playground.ajax,
      data: {
        action: section.getAttribute("data-ajax")
      },
      success: function(output) {
        var elem = document.createElement("div");
        $('[data-ajax="' + section.getAttribute("data-ajax") +'"]').html(output);
        // $item.appendChild(elem);
        if (output.includes("slick-slider-js")) {
          $(".slick-slider-js").slick();
        }
      }
    });
  };
})(jQuery);

(function($) {
  // Variables
  var elems = document.querySelectorAll("[data-sm-text], [data-md-text]");

  // Functions
  var _initText = function() {
    // Conditional
    if (window.innerWidth > 1199) return;
    // Loop
    elems.forEach(_setText);
  };

  var _setText = function(el) {
    if (
      el.dataset.mdText != undefined &&
      el.dataset.mdText.length > 0 &&
      window.innerWidth < 1199 &&
      window.innerWidth > 767
    ) {
      el.textContent = el.dataset.mdText;
    }
    if (
      el.dataset.smText != undefined &&
      el.dataset.smText.length > 0 &&
      window.innerWidth < 768
    ) {
      el.textContent = el.dataset.smText;
    }
  };

  // Events
  $(document).ready(_initText);
})(jQuery);
